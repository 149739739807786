import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { YesNo } from './YesNo'
import { Text } from './Text'
import { LongText } from './LongText'
import { SelectAllThatApply } from './SelectAllThatApply'
import { MultipleChoice } from './MultipleChoice'
import { Number } from './Number'
import { Date } from './Date'
import { AgreeDisagree } from './AgreeDisagree'
import { formatDateTime } from '../common/utilities'

export function QuestionSetDetail () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({ answers: [], participants: [{ id: 1, answers: [] }] })
  const { questionSetId } = useParams()
  const { data: questionSet } = useGet(`/api/question-set/${questionSetId}`, true)
  const confirmDelete = useConfirm('Delete question set?', 'Delete question set permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/question-set/${questionSetId}`, () => { navigate('/question-set') })

  if (questionSet != null) {
    return (
      <RadAppLayout
        name={questionSet.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')} disabled={questionSet.activeSurveyCount !== '0'}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
                <RadButton onClick={() => navigate(`/question-set/create?sourceId=${questionSetId}`)}>Copy</RadButton>
              </RadSpaceBetween>
            }
            description={questionSet.description}
          >
            {questionSet.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Assessment Type
                  </RadBox>
                  <RadLink href={`/assessment-type/${questionSet.surveyTypeId}`}>{questionSet.surveyType.name}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Created
                  </RadBox>
                  {formatDateTime(questionSet.createdAt)}
                </div>
              </RadColumnLayout>
            </RadContainer>
            {questionSet.sections.map((section) =>
              <RadContainer
                key={`section-${section.id}`}
                header={
                  <RadHeader
                    variant='h2'
                    description={section.introduction ?? 'No introduction entered.'}
                  >
                    {section.name}
                  </RadHeader>
                }
              >
                <RadSpaceBetween size='l'>
                  {section.questions.map((q) => {
                    switch (q.type.name) {
                      case 'Yes/No':
                        return <YesNo key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Text':
                        return <Text key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Long Text':
                        return <LongText key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Select All That Apply':
                        return <SelectAllThatApply key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Multiple Choice':
                        return <MultipleChoice key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Number':
                        return <Number key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Date':
                        return <Date key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      case 'Agree / Disagree':
                        return <AgreeDisagree key={`question-${q.id}`} question={q} formValues={formValues} setFormValues={setFormValues} />
                      default:
                        return <span key={`question-${q.id}`}>{`No definition for ${q.type.name}`}</span>
                    }
                  })}
                  {section.questions.length === 0 && <RadBox color='text-body-secondary'>No questions added to this section.</RadBox>}
                </RadSpaceBetween>
              </RadContainer>
            )}
          </RadSpaceBetween>
        }
      />
    )
  }
}
