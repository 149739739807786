import React, { useEffect, useState, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { Stream } from '@cloudflare/stream-react'
import { useIsMobile } from '../hooks/useIsMobile'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { AppContext } from './../App'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadFormField } from '../common/RadFormField'
import { RadProgressBar } from '../common/RadProgressBar'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatTime } from '../common/utilities'
import { Date } from '../questionSet/Date'
import { LongText } from '../questionSet/LongText'
import { MultipleChoice } from '../questionSet/MultipleChoice'
import { Number } from '../questionSet/Number'
import { SelectAllThatApply } from '../questionSet/SelectAllThatApply'
import { Text } from '../questionSet/Text'
import './TakeSurvey.scss'

export function TakeSurvey () {
  const { setError } = useContext(AppContext)
  const streamRef = useRef(null)
  const isMobile = useIsMobile()
  const { uuid } = useParams()
  const [time, setTime] = useState(0)
  const [resumed, setResumed] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [formValues, setFormValues] = useState()
  const { data: survey } = useGet(`/api/participant/survey/${uuid}?_${Math.round(time / 60000)}`, true)
  const { data: participant } = useGet(`/api/participant/survey/${uuid}/participant`, true)
  const { data: ageOptions } = useGet('/api/participant/option/type?entity=age')
  const { data: educationLevelOptions } = useGet('/api/participant/option/type?entity=education_level')
  const { data: ethnicityOptions } = useGet('/api/participant/option/type?entity=race_and_ethnicity')
  const { data: genderOptions } = useGet('/api/participant/option/type?entity=gender')
  const { data: positionLevelOptions } = useGet('/api/participant/option/type?entity=position_level')
  const { data: workEnvironmentOptions } = useGet('/api/participant/option/type?entity=work_environment')
  const { data: yearsWithCompanyOptions } = useGet('/api/participant/option/type?entity=years_with_company')
  const updateParticipantAndAdvance = usePut(`/api/participant/${uuid}`, formValues, () => { setCurrentStep(currentStep + 1) })
  const updateParticipant = usePut(`/api/participant/${uuid}`, formValues)
  moment.tz.setDefault('America/Chicago')

  useEffect(() => {
    console.log('here')
    if (formValues == null) {
      setFormValues(participant)
    }
  }, [formValues, participant])

  useEffect(() => {
    setInterval(() => {
      setTime(moment().unix())
    }, 1000)
  }, [])

  const parseValue = (str) => {
    if (str.includes('<')) return -1
    if (str.includes('+')) return Infinity
    return parseInt(str.split('-')[0])
  }

  const getTitle = (step) => {
    if (step.demographic != null) {
      return 'Demographics'
    } else if (step.section?.name != null) {
      return step.section.name
    } else if (step.title != null) {
      return step.title
    }
  }

  const getDescription = (step) => {
    if (step.demographic != null) {
      return 'Please answer the following questions before starting the survey.'
    } else if (step.section?.introduction != null) {
      return step.section.introduction
    } else if (step.description != null) {
      return step.description
    }
  }

  function getWindowDimensions () {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  if (
    survey != null &&
    participant != null &&
    formValues != null &&
    ageOptions != null &&
    educationLevelOptions != null &&
    ethnicityOptions != null &&
    genderOptions != null &&
    positionLevelOptions != null &&
    workEnvironmentOptions != null &&
    yearsWithCompanyOptions != null
  ) {
    const departmentOptions = survey.departments
      .map((x) => ({ value: x.name, label: x.name }))
    const subdepartmentOptions = survey.departments
      .find((x) => x.name === formValues.department)?.subdepartments
      .map((x) => ({ value: x.name, label: x.name }))
    const locationOptions = survey.locations
      .map((x) => ({ value: x.name, label: x.name }))

    if (moment().isBefore(moment(survey.startAt))) {
      const timeRemaining = moment.duration(moment(survey.startAt).unix() - time, 'seconds')
      return (
        <div className='survey'>
          <RadBox variant='h1' padding={{ bottom: 'l' }}>{survey.name}</RadBox>
          <RadSpaceBetween size='l'>
            <RadContainer>
              {survey.client.logo != null &&
                <RadBox float={isMobile ? null : 'right'}>
                  <img src={`/api/storage/${survey.client.logo.path}`} style={{ height: '60px' }} alt='Organization Logo' />
                </RadBox>}
              <RadBox variant='h2'>Survey Not Started</RadBox>
              <RadBox>{`Survey opens ${formatDate(survey.startAt)} at ${formatTime(survey.startAt)}`}</RadBox>
              <RadBox margin={{ top: 'xxl', bottom: 'xxl' }} textAlign='center'>
                <RadBox margin={{ top: 'xxl' }} textAlign='center'>
                  <div>
                    <RadBox fontSize='heading-l' display='inline-block' margin={{ right: 'xl' }}>
                      <RadBox fontSize='display-l' color='text-status-info' fontWeight='bold'>{timeRemaining.days()}</RadBox>
                      days
                    </RadBox>
                    <RadBox fontSize='heading-l' display='inline-block' margin={{ right: 'xl' }}>
                      <RadBox fontSize='display-l' color='text-status-info' fontWeight='bold'>{timeRemaining.hours()}</RadBox>
                      hours
                    </RadBox>
                    <RadBox fontSize='heading-l' display='inline-block' margin={{ right: 'xl' }}>
                      <RadBox fontSize='display-l' color='text-status-info' fontWeight='bold'>{timeRemaining.minutes()}</RadBox>
                      minutes
                    </RadBox>
                    <RadBox fontSize='heading-l' display='inline-block'>
                      <RadBox fontSize='display-l' color='text-status-info' fontWeight='bold'>{timeRemaining.seconds()}</RadBox>
                      seconds
                    </RadBox>
                  </div>
                </RadBox>
              </RadBox>
            </RadContainer>
          </RadSpaceBetween>
        </div>
      )
    }
    if (moment().isAfter(moment(survey.endAt))) {
      return (
        <div className='survey'>
          <RadBox variant='h1' padding={{ bottom: 'l' }}>{survey.name}</RadBox>
          <RadSpaceBetween size='l'>
            <RadContainer fitHeight>
              {survey.client.logo != null &&
                <RadBox float={isMobile ? null : 'right'}>
                  <img src={`/api/storage/${survey.client.logo.path}`} style={{ height: '60px' }} alt='Organization Logo' />
                </RadBox>}
              <RadBox variant='h2'>Survey Closed</RadBox>
              <RadBox>{`Survey closed ${formatDate(survey.endAt)} at ${formatTime(survey.endAt)}`}</RadBox>
            </RadContainer>
          </RadSpaceBetween>
        </div>
      )
    }
    if (survey.isClosed) {
      return (
        <div className='survey'>
          <RadBox variant='h1' padding={{ bottom: 'l' }}>{survey.name}</RadBox>
          <RadSpaceBetween size='l'>
            <RadContainer fitHeight>
              {survey.client.logo != null &&
                <RadBox float={isMobile ? null : 'right'}>
                  <img src={`/api/storage/${survey.client.logo.path}`} style={{ height: '60px' }} alt='Organization Logo' />
                </RadBox>}
              <RadBox variant='h2'>Survey Closed</RadBox>
              <RadBox fontSize='body-m' padding={{ top: 'xxl', bottom: 'xxl' }} textAlign='center'>
                {survey.pausedMessage != null ? <RadBox padding={{ top: 'l' }}><div dangerouslySetInnerHTML={{ __html: survey.closedMessage }} /></RadBox> : null}
              </RadBox>
            </RadContainer>
          </RadSpaceBetween>
        </div>
      )
    }
    if (survey.isPaused) {
      return (
        <div className='survey'>
          <RadBox variant='h1' padding={{ bottom: 'l' }}>{survey.name}</RadBox>
          <RadSpaceBetween size='l'>
            <RadContainer fitHeight>
              {survey.client.logo != null &&
                <RadBox float={isMobile ? null : 'right'}>
                  <img src={`/api/storage/${survey.client.logo.path}`} style={{ height: '60px' }} alt='Organization Logo' />
                </RadBox>}
              <RadBox variant='h2'>Survey Paused</RadBox>
              <RadBox fontSize='body-m' padding={{ top: 'xxl', bottom: 'xxl' }} textAlign='center'>
                {survey.pausedMessage != null ? <RadBox padding={{ top: 'l' }}><div dangerouslySetInnerHTML={{ __html: survey.pausedMessage }} /></RadBox> : null}
              </RadBox>
            </RadContainer>
          </RadSpaceBetween>
        </div>
      )
    }
    if (survey.participants[0].status === 'Done' || formValues.status === 'Done') {
      return (
        <div className='survey'>
          <RadBox variant='h1' padding={{ bottom: 'l' }}>{survey.name}</RadBox>
          <RadSpaceBetween size='l'>
            <RadContainer fitHeight>
              {survey.client.logo != null &&
                <RadBox float={isMobile ? null : 'right'}>
                  <img src={`/api/storage/${survey.client.logo.path}`} style={{ height: '60px' }} alt='Organization Logo' />
                </RadBox>}
              <RadBox variant='h2'>Survey Complete</RadBox>
              <RadBox fontSize='body-m' padding={{ top: 'xxl', bottom: 'xxl' }} textAlign='center'>
                Thanks for completing the assessment! No further actions are needed at this time.
                <br />
                Your results will now be combined with other participants and analyzed to provide valuable feedback to your leadership team.
              </RadBox>
            </RadContainer>
          </RadSpaceBetween>
        </div>
      )
    }

    const participant = formValues
    const intro = {
      title: 'About the Organizational Health Assessment',
      description: 'The next button will activate once the video has completed.',
      surveyVideoLink: survey.questionSet.surveyType.surveyVideoLink
    }
    const demographics = []
    if (survey.selfIdentifyDepartment !== 0) {
      demographics.push({ demographic: 'department', text: 'What\'s your department?', items: departmentOptions })
    }
    if (survey.selfIdentifySubdepartment !== 0 && subdepartmentOptions != null && subdepartmentOptions.length > 0) {
      demographics.push({ demographic: 'subdepartment', text: 'What\'s your subdepartment?', items: subdepartmentOptions })
    }
    if (survey.selfIdentifyGender === 1) {
      demographics.push({ demographic: 'gender', text: 'What\'s your gender?', items: genderOptions.concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyGender === 2) {
      demographics.push({ demographic: 'gender', text: 'What\'s your gender?', items: genderOptions })
    }
    if (survey.selfIdentifyEthnicity === 1) {
      demographics.push({ demographic: 'ethnicity', text: 'What\'s your race and ethnicity?', items: ethnicityOptions.concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyEthnicity === 2) {
      demographics.push({ demographic: 'ethnicity', text: 'What\'s your race and ethnicity?', items: ethnicityOptions })
    }
    if (survey.selfIdentifyAge === 1) {
      demographics.push({ demographic: 'age', text: 'What\'s your age?', items: ageOptions.sort((a, b) => parseValue(a.value) - parseValue(b.value)).concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyAge === 2) {
      demographics.push({ demographic: 'age', text: 'What\'s your age?', items: ageOptions.sort((a, b) => parseValue(a.value) - parseValue(b.value)) })
    }
    if (survey.selfIdentifyYearsWithCompany === 1) {
      demographics.push({ demographic: 'yearsWithCompany', text: `How long have you been with ${survey.client.name}?`, items: yearsWithCompanyOptions.sort((a, b) => parseValue(a.value) - parseValue(b.value)).concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyYearsWithCompany === 2) {
      demographics.push({ demographic: 'yearsWithCompany', text: `How long have you been with ${survey.client.name}?`, items: yearsWithCompanyOptions.sort((a, b) => parseValue(a.value) - parseValue(b.value)) })
    }
    if (survey.selfIdentifyPositionLevel === 1) {
      demographics.push({ demographic: 'positionLevel', text: 'What\'s your position level?', items: positionLevelOptions.concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyPositionLevel === 2) {
      demographics.push({ demographic: 'positionLevel', text: 'What\'s your position level?', items: positionLevelOptions })
    }
    if (survey.selfIdentifyLocation === 1) {
      demographics.push({ demographic: 'location', text: 'What\'s your location?', items: locationOptions.concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyLocation === 2) {
      demographics.push({ demographic: 'location', text: 'What\'s your location?', items: locationOptions })
    }
    if (survey.selfIdentifyWorkEnvironment === 1) {
      demographics.push({ demographic: 'workEnvironment', text: 'What\'s your work environment?', items: workEnvironmentOptions.concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyWorkEnvironment === 2) {
      demographics.push({ demographic: 'workEnvironment', text: 'What\'s your work environment?', items: workEnvironmentOptions })
    }
    if (survey.selfIdentifyEducationLevel === 1) {
      demographics.push({ demographic: 'educationLevel', text: 'What\'s your education level?', type: 'autosuggest', items: educationLevelOptions.concat([{ value: 'Prefer not to say', label: 'Prefer not to say' }]) })
    }
    if (survey.selfIdentifyEducationLevel === 2) {
      demographics.push({ demographic: 'educationLevel', text: 'What\'s your education level?', type: 'autosuggest', items: educationLevelOptions })
    }

    const questions = survey.questionSet.sections.flatMap((s) => s.questions.map((q) => ({ ...q, section: { name: s.name, introduction: s.introduction } })))

    const steps = (survey.questionSet.surveyType.surveyVideoLink != null ? [intro] : []).concat(demographics).concat(questions)
    const step = steps[currentStep]

    if (!resumed && participant.answers.length > 0) {
      console.log(`currentStep: ${currentStep}`)
      const lastQuestionAnswered = participant.answers[participant.answers.length - 1]
      let newStep = steps.findIndex((s) => s.id === lastQuestionAnswered.questionId) + 1
      if (newStep >= steps.length) newStep--
      setResumed(true)
      setCurrentStep(newStep)
    }

    const next = () => {
      if (currentStep > 1 && steps[currentStep - 1].demographic == null) {
        setFormValues({ ...formValues, status: 'In Progress' })
      }
      if (currentStep < steps.length - 1) {
        updateParticipantAndAdvance()
      } else {
        updateParticipant()
      }
    }
    const previous = () => {
      setFormValues({ ...formValues, [steps[currentStep].field]: formValues[steps[currentStep].field] })
      setCurrentStep(currentStep - 1)
      setError(null)
    }
    const done = () => {
      setFormValues({ ...formValues, status: 'Done' })
      updateParticipant()
    }

    if (steps.length === 0) {
      return (
        <RadBox fontSize='heading-xl' padding={{ top: 'xxl', bottom: 'xxl' }} textAlign='center'>
          No Steps. Set Allow Self Identify to true or add questions to the survey.
        </RadBox>
      )
    }

    return (
      <div
        className='survey'
        onClick={() => setHovered(false)}
      >
        <RadBox variant='h1' padding={{ bottom: 'l' }}>{survey.name}</RadBox>
        <RadSpaceBetween size='l'>
          <RadContainer>
            {survey.client.logo != null &&
              <RadBox float={isMobile ? null : 'right'}>
                <img src={`/api/storage/${survey.client.logo.path}`} style={{ height: '60px' }} alt='Organization Logo' />
              </RadBox>}
            <RadBox variant='h2'>{getTitle(step)}</RadBox>
            <RadBox>{getDescription(step)}</RadBox>
            <RadBox padding={{ bottom: 'l', top: 'l' }}>
              {step.demographic != null &&
                <RadBox padding={{ top: 'xxl', bottom: 'xxl' }}>
                  <RadBox variant='awsui-key-label' padding={{ bottom: 's' }}>{step.text}</RadBox>
                  <RadFormField field={step.demographic}>
                    <RadRadioGroup
                      onChange={({ detail }) => {
                        setFormValues({ ...formValues, [step.demographic]: detail.value })
                        next()
                      }}
                      value={participant[step.demographic]}
                      items={step.items}
                    />
                  </RadFormField>
                </RadBox>}
              {step.surveyVideoLink != null &&
                <RadSpaceBetween size='l'>
                  <div
                    className='video-container'
                    onMouseEnter={() => {
                      setHovered(true)
                      setTimeout(() => { setHovered(false) }, 3000)
                    }}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => setHovered(!hovered)}
                  >
                    <Stream
                      preload='auto'
                      streamRef={streamRef}
                      responsive={isMobile}
                      src={step.surveyVideoLink}
                      onEnded={() => { participant.watchedIntro = true }}
                      width='100%'
                      height={Math.min(
                        getWindowDimensions().height - 360,
                        (Math.min(getWindowDimensions().width - 80, 1240) / 16 * 9)
                      )}
                    />
                  </div>
                </RadSpaceBetween>}
              {step.demographic == null && step.surveyVideoLink == null &&
                <RadBox padding={{ top: 'xl', bottom: 'xxl' }} textAlign='center'>
                  <RadProgressBar
                    value={participant.answers.length / questions.length * 100}
                  />
                </RadBox>}
              {step.type?.name === 'Date' &&
                <RadFormField field={step.field}>
                  <Date
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
              {step.type?.name === 'Long Text' &&
                <RadFormField field={step.field}>
                  <LongText
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
              {step.type?.name === 'Agree / Disagree' &&
                <RadFormField field={step.field}>
                  <MultipleChoice
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
              {step.type?.name === 'Multiple Choice' &&
                <RadFormField field={step.field}>
                  <MultipleChoice
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
              {step.type?.name === 'Number' &&
                <RadFormField field={step.field}>
                  <Number
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
              {step.type?.name === 'Select All That Apply' &&
                <RadFormField field={step.field}>
                  <SelectAllThatApply
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
              {step.type?.name === 'Text' &&
                <RadFormField field={step.field}>
                  <Text
                    question={step}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    next={next}
                  />
                </RadFormField>}
            </RadBox>
            <RadBox padding={{ top: 'xxl' }} textAlign='center' float='left'>
              If you have trouble completing this survey please contact your sponsor.
            </RadBox>
            <RadBox padding={{ top: 'xxl' }} float='right'>
              <RadSpaceBetween direction='horizontal' size='xs'>
                {currentStep > 0 &&
                  <RadButton
                    onClick={previous}
                  >
                    Previous
                  </RadButton>}
                {currentStep < steps.length - 1 &&
                  <RadButton
                    variant='primary'
                    disabled={
                      (step.demographic != null && participant[step.demographic] == null) ||
                        (step.answers != null && participant.answers.find((x) => x.questionId === step.id)?.text == null && step.isOptional !== true) ||
                        (step.surveyVideoLink != null && participant.watchedIntro !== true)
                    }
                    onClick={next}
                  >
                    Next
                  </RadButton>}
                {currentStep === steps.length - 1 &&
                  <RadButton
                    variant='primary'
                    onClick={done}
                    disabled={
                      participant.answers.filter((x) => questions.find((y) => y.id === x.questionId && !y.isOptional)).length !== questions.filter((x) => !x.isOptional).length
                    }
                  >
                    Done
                  </RadButton>}
              </RadSpaceBetween>
            </RadBox>
          </RadContainer>
        </RadSpaceBetween>
      </div>
    )
  }
}
