import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Papa from 'papaparse'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadExpandableSection } from '../common/RadExpandableSection'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function QuestionSetEdit () {
  const fileInput = useRef(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { questionSetId } = useParams()
  const [sourceId] = useState(searchParams.get('sourceId'))
  const [formValues, setFormValues] = useState()
  const { data: source } = useGet(sourceId ? `/api/question-set/${sourceId}` : null)
  const { data: questionSet } = useGet(questionSetId ? `/api/question-set/${questionSetId}` : null, true)
  const { data: questionTypeOptions } = useGet('/api/option/type?entity=question&required=true')
  const { data: surveyTypeOptions } = useGet('/api/option/survey-type')
  const { data: questionCategories } = useGet('/api/question-category')

  useEffect(() => {
    const defaultFormValues = source == null ? { sections: [], questions: [] } : { ...source, id: null, name: `${source.name} (copy)` }
    setFormValues(questionSet ?? defaultFormValues)
  }, [source, questionSet])

  const create = usePost('/api/question-set', formValues, (resp) => { navigate(`/question-set/${resp.id}`) })
  const update = usePut(`/api/question-set/${questionSetId}`, formValues, (resp) => { navigate(`/question-set/${questionSetId}`) })

  async function saveChanges () {
    if (questionSetId != null) { update() } else { create() }
  }

  async function cancel () {
    if (questionSetId != null) {
      navigate(`/question-set/${questionSetId}`)
    } else {
      navigate('/question-set')
    }
  }

  function escapeCSVValue (value) {
    if (typeof value === 'string') {
      return `${value.replace(/"/g, '""')}`
    } else {
      return ''
    }
  }

  async function exportToCSV () {
    let csvContent =
      'data:text/csv;charset=utf-8,' +
      'Section Name,' +
      'Section Introduction,' +
      'Question Category,' +
      'Question Subcategory,' +
      'Question Type,' +
      'Question Text,' +
      'Question Context,' +
      'Question Optional,' +
      'Question Internal,' +
      'Question Include Other,' +
      'Question Answers' +
      '\n'
    for (const section of formValues.sections) {
      for (const question of section.questions) {
        csvContent +=
          `"${escapeCSVValue(section.name)}",` +
          `"${escapeCSVValue(section.introduction)}",` +
          `"${escapeCSVValue(question.category?.name)}",` +
          `"${escapeCSVValue(question.subcategory?.name)}",` +
          `"${escapeCSVValue(question.type?.name)}",` +
          `"${escapeCSVValue(question.text)}",` +
          `"${escapeCSVValue(question.context)}",` +
          `"${question.isOptional ? 'true' : 'false'}",` +
          `"${question.isInternal ? 'true' : 'false'}",` +
          `"${question.hasOther ? 'true' : 'false'}",` +
          `"${escapeCSVValue((question.answers ?? []).map(answer => `${answer.text} ${answer.value}`).join(', '))}"` +
          '\n'
      }
    }
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'question_set.csv')
    document.body.appendChild(link)
    link.click()
  }

  async function importFromCSV () {
    const file = fileInput.current.files[0]
    if (file == null) return

    const sections = formValues.sections
    const reader = new FileReader()
    reader.onload = function (e) {
      const csv = e.target.result
      const data = Papa.parse(csv, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        newline: '\r\n',
        skipEmptyLines: true,
        columns: null
      })

      for (const line of data.data) {
        console.log(line)
        let section = formValues.sections.find(section => section.name === line['Section Name'])
        if (section == null) {
          section = {
            id: uuidv4(),
            sortOrder: formValues.sections.length + 1,
            name: line['Section Name'],
            introduction: line['Section Introduction'],
            questions: []
          }
          sections.push(section)
        }
        let question = section.questions.find(question => question.text === line['Question Text'])
        if (question == null) {
          const type = questionTypeOptions.find(type => type.label === line['Question Type'])
          const category = questionCategories.find(category => category.name === line['Question Category']) ?? { subcategories: [] }
          const subcategory = category.subcategories.find(subcategory => subcategory.name === line['Question Subcategory'])
          question = {
            sortOrder: section.questions.length + 1,
            id: uuidv4(),
            questionCategoryId: category?.id,
            questionSubcategoryId: subcategory?.id,
            typeId: type != null ? parseInt(type.value) : null,
            type: { name: type?.label },
            text: line['Question Text'],
            context: line['Question Context'],
            isOptional: line['Question Optional'] === 'TRUE',
            isInternal: line['Question Internal'] === 'TRUE',
            hasOther: line['Question Include Other'] === 'TRUE',
            answers: line['Question Answers'] === null || line['Question Answers'] === ''
              ? []
              : line['Question Answers'].split(',').map(answer => ({
                id: uuidv4(),
                text: answer.replace(answer.split(' ').pop(), ''),
                value: answer.split(' ').pop()
              }))
          }
          section.questions.push(question)
        }
      }
      setFormValues({ ...formValues, sections })
      fileInput.current.value = null
    }
    reader.readAsText(file)
  }

  if (
    formValues != null &&
    questionTypeOptions != null &&
    surveyTypeOptions != null &&
    questionCategories != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => fileInput.current.click()}>Import</RadButton>
                <RadButton onClick={exportToCSV}>Export</RadButton>
              </RadSpaceBetween>
            }
          >
            {formValues.id != null ? 'Edit Question Set' : 'New Question Set'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
              secondaryActions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton
                    onClick={() => {
                      const max = Math.max(...formValues.sections.map((x) => x.sortOrder))
                      const sortOrder = max === -Infinity ? 1 : max + 1
                      const newSection = { uuid: uuidv4(), sortOrder, questions: [] }
                      const newSections = [...formValues.sections, newSection]
                      setFormValues({ ...formValues, sections: newSections })
                    }}
                  >
                    Add new section
                  </RadButton>
                </RadSpaceBetween>
              }
            >
              <input type='file' ref={fileInput} onChange={importFromCSV} style={{ display: 'none' }} />
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Assessment Type *' field='surveyTypeId'>
                      <RadSelect
                        selectedOption={formValues.surveyTypeId ? surveyTypeOptions.find(x => x.value === formValues.surveyTypeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          const sections = formValues.sections
                            .map((x) => { return { ...x, questions: x.questions.map((y) => { return { ...y, questionCategoryId: null, questionSubcategoryId: null } }) } })
                          setFormValues({ ...formValues, sections, surveyTypeId: parseInt(detail.selectedOption.value) })
                        }}
                        options={surveyTypeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                {formValues.sections.map((section, index) =>
                  <RadExpandableSection
                    variant='container'
                    defaultExpanded
                    key={`section-${index}`}
                    headerText='Section'
                    headerActions={
                      <RadSpaceBetween direction='horizontal' size='xs'>
                        <RadButton
                          onClick={() => {
                            const newSections = formValues.sections.filter(x => x.uuid !== section.uuid)
                            setFormValues({ ...formValues, sections: newSections })
                          }}
                        >
                          Remove section
                        </RadButton>
                      </RadSpaceBetween>
                    }
                  >
                    <RadSpaceBetween size='l'>
                      <RadFormField label='Name *' field={`sections.${section.id || section.uuid}.name`}>
                        <RadInput
                          placeholder='Enter name'
                          ariaRequired
                          value={formValues.sections[index].name}
                          onChange={({ detail }) => {
                            const sections = formValues.sections
                            const section = sections[index]
                            section.name = detail.value
                            setFormValues({ ...formValues, sections })
                          }}
                        />
                      </RadFormField>
                      <RadFormField label='Introduction' field={`sections.${section.id || section.uuid}.introduction`}>
                        <RadTextarea
                          placeholder='Enter introduction'
                          value={formValues.sections[index].introduction}
                          onChange={({ detail }) => {
                            const sections = formValues.sections
                            const section = sections[index]
                            section.introduction = detail.value
                            setFormValues({ ...formValues, sections })
                          }}
                        />
                      </RadFormField>
                      <RadHeader variant='h3'>
                        Questions
                        {formValues.sections[index].questions.length === 0 &&
                          <RadBox color='text-body-secondary' padding={{ top: 'l' }}>No questions added to this section.</RadBox>}
                      </RadHeader>
                      {formValues.sections[index].questions.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1).map((x) =>
                        <QuestionEditor
                          key={`question-${x.id},${x.uuid}`}
                          item={x}
                          formValues={formValues}
                          sectionIndex={index}
                          setFormValues={setFormValues}
                          questionTypeOptions={questionTypeOptions}
                          questionCategories={questionCategories}
                        />)}
                      <RadButton
                        onClick={() => {
                          const sections = formValues.sections
                          const section = sections[index]
                          const questions = section.questions
                          const max = Math.max(...questions.map((x) => x.sortOrder))
                          const sortOrder = max === -Infinity ? 1 : max + 1
                          questions.push({ uuid: uuidv4(), sortOrder, type: { name: null }, isOptional: false, isInternal: false, hasOther: false })
                          setFormValues({ ...formValues, sections })
                        }}
                      >
                        Add new question
                      </RadButton>
                    </RadSpaceBetween>
                  </RadExpandableSection>
                )}
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function QuestionEditor ({ item, formValues, sectionIndex, setFormValues, questionTypeOptions, questionCategories }) {
  const sections = formValues.sections
  const section = sections[sectionIndex]
  const questions = section.questions
  const question = questions
    .find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
  const categoryOptions = questionCategories
    .filter((x) => x.surveyType.id === formValues.surveyTypeId)
    .map((x) => { return { value: x.id.toString(), label: x.name, disabled: x.isDisabled } })
  const subcategoryOptions = question.questionCategoryId != null
    ? questionCategories
      .find((x) => x.id === question.questionCategoryId)
      .subcategories
      .map((x) => { return { value: x.id.toString(), label: x.name, disabled: x.isDisabled } })
    : []

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 1 } },
          { colspan: { default: 12, xxs: 1 } },
          { colspan: { default: 12, xxs: 12 } },
          { colspan: { default: 12, xxs: 12 } },
          { colspan: { default: 12, xxs: 12 } }
        ]}
      >
        <RadFormField label='Category *' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.questionCategoryId`}>
          <RadSelect
            selectedOption={categoryOptions.find(x => x.value === question.questionCategoryId?.toString())}
            onChange={({ detail }) => {
              question.questionCategoryId = parseInt(detail.selectedOption.value)
              question.questionSubcategoryId = null
              question.category = { name: detail.selectedOption.label }
              setFormValues({ ...formValues, sections })
            }}
            options={categoryOptions.filter(x => !x.disabled)}
            placeholder='Choose a category'
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Subcategory *' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.questionSubcategoryId`}>
          <RadSelect
            selectedOption={subcategoryOptions.find(x => x.value === question.questionSubcategoryId?.toString())}
            onChange={({ detail }) => {
              question.questionSubcategoryId = parseInt(detail.selectedOption.value)
              question.subcategory = { name: detail.selectedOption.label }
              setFormValues({ ...formValues, sections })
            }}
            options={subcategoryOptions.filter(x => !x.disabled)}
            placeholder='Choose a subcategory'
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Question type *' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.surveyTypeId`}>
          <RadSelect
            selectedOption={questionTypeOptions.find(x => parseInt(x.value) === item.typeId)}
            options={questionTypeOptions}
            filteringType='auto'
            placeholder='Choose a type'
            onChange={({ detail }) => {
              question.typeId = parseInt(detail.selectedOption.value)
              question.type = { name: detail.selectedOption.label }
              if (question.type.name !== 'Select All That Apply' && question.type.name !== 'Agree / Disagree' && question.type.name !== 'Multiple Choice') {
                question.answers = []
                question.hasOther = false
              } else if (question.answers == null) {
                question.answers = []
                question.hasOther = false
              }
              if (question.type.name === 'Agree / Disagree') {
                question.answers = [
                  { uuid: uuidv4(), text: 'Strongly Agree', questionId: question.id, questionUuid: question.uuid, value: 1.0 },
                  { uuid: uuidv4(), text: 'Agree', questionId: question.id, questionUuid: question.uuid, value: 0.75 },
                  { uuid: uuidv4(), text: 'Unsure / I Don\'t Know', questionId: question.id, questionUuid: question.uuid, value: 0.0 },
                  { uuid: uuidv4(), text: 'Disagree', questionId: question.id, questionUuid: question.uuid, value: -0.25 },
                  { uuid: uuidv4(), text: 'Strongly Disagree', questionId: question.id, questionUuid: question.uuid, value: -0.5 }
                ]
              }
              setFormValues({ ...formValues, sections })
            }}
            expandToViewport
          />
        </RadFormField>
        <RadFormField label='Optional' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.isOptional`}>
          <RadCheckbox
            checked={item.isOptional}
            onChange={({ detail }) => {
              item.isOptional = detail.checked
              setFormValues({ ...formValues, sections })
            }}
          />
        </RadFormField>
        <RadFormField label='Internal' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.isInternal`}>
          <RadCheckbox
            checked={item.isInternal}
            onChange={({ detail }) => {
              item.isInternal = detail.checked
              setFormValues({ ...formValues, sections })
            }}
          />
        </RadFormField>
        <RadFormField label='Question *' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.text`}>
          <RadInput
            value={item.text}
            placeholder='Enter question'
            onChange={({ detail }) => {
              question.text = detail.value
              setFormValues({ ...formValues, sections })
            }}
          />
        </RadFormField>
        <RadFormField label='Context' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.context`}>
          <RadTextarea
            value={item.context}
            placeholder='Enter context for question'
            onChange={({ detail }) => {
              question.context = detail.value
              setFormValues({ ...formValues, sections })
            }}
          />
        </RadFormField>
        {(question.type.name === 'Multiple Choice') &&
          <RadFormField label='Include other (please specify) answer' field={`sections.${section.id || section.uuid}.questions.${item.id || item.uuid}.hasOther`}>
            <RadCheckbox
              checked={item.hasOther}
              onChange={({ detail }) => {
                item.hasOther = detail.checked
                setFormValues({ ...formValues, sections })
              }}
            />
          </RadFormField>}
      </RadGrid>
      {/* {(question.type.name !== 'Select All That Apply' && question.type.name !== 'Agree / Disagree' && question.type.name !== 'Multiple Choice') && <span />} */}
      {(question.type.name === 'Select All That Apply' || question.type.name === 'Agree / Disagree' || question.type.name === 'Multiple Choice') &&
        <RadSpaceBetween size='l'>
          <RadHeader variant='h3'>
            Answers
            {item.answers.length === 0 &&
              <RadBox color='text-body-secondary' padding={{ top: 'l' }}>No answers added to this section.</RadBox>}
          </RadHeader>
          {item.answers.map((x) =>
            <AnswerEditor
              key={`question-${x.id},${x.uuid}`}
              item={x}
              formValues={formValues}
              setFormValues={setFormValues}
              section={section}
              question={item}
            />)}
          <RadButton
            onClick={() => {
              item.answers.push({ uuid: uuidv4(), questionId: question.id, questionUuid: question.uuid })
              setFormValues({ ...formValues, sections })
            }}
          >
            Add new answer
          </RadButton>
        </RadSpaceBetween>}
      <RadBox float='right'>
        <RadSpaceBetween size='xs' direction='horizontal'>
          <RadButton
            onClick={() => {
              section.questions = questions.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1).map((x) => {
                const y = x
                if (y.sortOrder === item.sortOrder - 1) {
                  y.sortOrder = y.sortOrder + 1
                } else if (y.sortOrder === item.sortOrder) {
                  y.sortOrder = y.sortOrder - 1
                }
                return y
              })
              setFormValues({ ...formValues, sections })
            }}
            disabled={item.sortOrder === 1}
          >
            Move question up
          </RadButton>
          <RadButton
            onClick={() => {
              section.questions = questions.sort((a, b) => (a.sortOrder > b.sortOrder) ? -1 : 1).map((x) => {
                const y = x
                if (y.sortOrder === item.sortOrder + 1) {
                  y.sortOrder = y.sortOrder - 1
                } else if (y.sortOrder === item.sortOrder) {
                  y.sortOrder = y.sortOrder + 1
                }
                return y
              })
              setFormValues({ ...formValues, sections })
            }}
            disabled={item.sortOrder === Math.max(...questions.map((x) => x.sortOrder))}
          >
            Move question down
          </RadButton>
          <RadButton
            onClick={() => {
              section.questions = questions
                .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
                .map((x) => {
                  const y = x
                  if (y.sortOrder > item.sortOrder) { y.sortOrder = y.sortOrder - 1 }
                  return y
                })
              setFormValues({ ...formValues, sections })
            }}
          >
            Remove question
          </RadButton>
        </RadSpaceBetween>
      </RadBox>
      <RadDivider />
    </RadSpaceBetween>
  )
}

function AnswerEditor ({ item, formValues, setFormValues, section, question }) {
  const sections = formValues.sections

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 5 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 5 } }
        ]}
      >
        <RadFormField label={item === question.answers[0] ? 'Answer *' : null} field={`sections.${section.id || section.uuid}.questions.${question.id || question.uuid}.answers.${item.id || item.uuid}.text`}>
          <RadInput
            value={item.text}
            placeholder='Enter answer'
            onChange={({ detail }) => {
              item.text = detail.value
              setFormValues({ ...formValues, sections })
            }}
          />
        </RadFormField>
        <RadFormField label={item === question.answers[0] ? 'Value *' : null} field={`sections.${section.id || section.uuid}.questions.${question.id || question.uuid}.answers.${item.id || item.uuid}.value`}>
          <RadInput
            value={item.value}
            placeholder='Enter value'
            onChange={({ detail }) => {
              item.value = detail.value
              setFormValues({ ...formValues, sections })
            }}
          />
        </RadFormField>
        <RadBox padding={{ top: item === question.answers[0] ? 'xl' : null }}>
          <RadButton
            onClick={() => {
              question.answers = question.answers
                .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, sections })
            }}
          >
            Remove answer
          </RadButton>
        </RadBox>
      </RadGrid>
      {/* <RadDivider /> */}
    </RadSpaceBetween>
  )
}
