import React, { useContext } from 'react'
import { AppContext } from './../App'
import FormField from '@cloudscape-design/components/form-field'

// https://cloudscape.design/components/form-field
export function RadFormField ({ field, ...rest }) {
  const { error } = useContext(AppContext)

  return (
    <FormField
      errorText={field != null && error != null && error.validationErrors != null ? error.validationErrors[field] : null}
      {...{ ...rest }}
    />
  )
}
